import { Component, ChangeDetectorRef } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { AngularFireAuth } from 'angularfire2/auth';
import { auth } from 'firebase';

@Component({
  selector: 'asc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Alliance Soccer Club';

  // constructor(public afAuth: AngularFireAuth) {
  // }

  // login() {
  //   let provider = new auth.GoogleAuthProvider();
  //   provider.setCustomParameters({
  //     'hd': 'eisterhold.net',
  //     'login_hint': 'user@eisterhold.net'
  //   });
  //   this.afAuth.auth.signInWithPopup(provider);
  // }

  // logout() {
  //   this.afAuth.auth.signOut();
  // }

  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  constructor(changeDetectorRef: ChangeDetectorRef, media: MediaMatcher) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }
}
